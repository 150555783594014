$black: black;
$white: white;
$dark: #202020;
$dark-form: #eeeeee;
$light-dark: #343434;

html[data-theme="dark"] {


  .top-proposta h1, .dashboard-contrato p, .nome-user, a, .box-common p, .box-common h5, .box-common h4, .box-common h3, .b box-common h2, .box-common label, .titulo-secao,
  #input-pesquisar, .planos-de-beneficios p, .main-info p, .dropdownNotification p, .anuncio-benefici h4, .nome-autor, .name-menu, .sessao-container p, .sessao-container h4,
  .dropdownNotification div, .dropdownNotification li, .top-proposta p, .anuncio-descricao div, .WordSection1 span, small, #dropdown-user div, #dropdown-user a, #dropdown-user i,
  .tela-welcome p, .description-ads div, .list-none li, #footer-content, .items-destaque, .criesuaconta, .name-user, .categorie-user, .moedas-lances,
  .experiencia, .habilidades-titulo, .preco, .menu_aside div, .header-contact button, .name-contact, .quantia-moedas-gasto, .meu-dashboard, .meu-dashboard:hover,
  .comentario-qualificacao, .avaliacao-publicacao-data, .control-dashboard h3, .control-dashboard label, .section-anuncio-public h5, .section-anuncio-public span, .section-anuncio-public p,
  .informacoes-primarias h2, .informacoes-adicionais li p, .informacoes-adicionais li span, .informacoes-proposta p, .titulo-empresa, .selecionado-idflow div h4, .selecionado-idflow div p, .subsecao h3, .subsecao span, .button-profile, .content-user-anuncio h2, .container-user-anunciante p, .container-user-anunciante h1,
  .projetos-realizados p, .projetos-pag p, #niveis-container h5, .items-moeda div p, .dashboard-contrato h3, .container, .aside-items div p, .text-notif span, .container-see-all p, .middle-proposta h1, .middle-proposta h2, .middle-proposta h3, .middle-proposta h4, .middle-proposta p, .middle-proposta a,
  .sessao-container th, .sessao-container td, .dash-section h4, .nivel-titulo {
    color: $white !important;
  }

  .fa-user {
    color: gray !important;
  }

  .item-separetor:hover, :root:hover, .box-common:hover, .box-message,
  .plano-content h4, .plano-content h3, .plano-content h2, .plano-content p, .ranking-tittle, .sincronizar-tittle, #tipo-anuncio option, #tipo-anuncio, .container-categoria p, .fa-qrcode, .certificacoes {
    color: $black !important;
  }

  .collapsed:hover, .item-others:hover {
    .fa-chevron-down, #metodo-pagamento, .metodo-pag {
      color: $black !important;
    }

    .fa-solid {
      color: $black !important;
    }
  }


  body, nav, .menu_aside, .list-group, .box-common, .cadastrese, .premium-ads, .tela-welcome, app-controle-feed, app-imagem-user, .header-contact,
  #footer-main, #main-form-body, .main-model, .dropdown-menu-moeda, .box-common a, .items-notif, #sessao-info-categoria, .sessao-container, .dropdownNotification, .dashboard-contrato, #metricas-perfil, .nav-pills, #BidContent, .container-form, .denuncia, .titulo-input, app-perfil, app-proposta, .medals span,
  .cadastrese div a, .custo, .habilidade {
    background-color: $dark !important;
    border-color: $light-dark !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none !important;
    color: $white !important
  }

  .items-anexados > .anex-item {
    background-color: $black !important;
  }

  nav, .menu_aside, .box-common, .cadastrese {
    border-color: $light-dark !important;
  }

  app-imagem-user {
    border-color: $dark !important;
  }

  #dropdown-user {
    background-color: $dark !important;
  }

  a {
    border-color: $light-dark !important;
  }

  .item-menu:hover {
    i {
      color: $black !important;
    }

    p {
      color: $black !important;
    }
  }

  .items-destaque, .fa-chevron-down {
    color: #8eaa23 !important;
  }

  .input-form-text, .item-menu.active:hover {
    background-color: $white !important;
  }

  .termos-contrato {
    background-color: none !important;
  }

  .item-menu.active {
    background-color: $dark-form !important;
  }

  .menu_aside, .card-body, .aside-item, .control-dashboard, .parte-proposta, .middle-proposta, .qualificacoes-freelas, .main-model,
  .project-bid, .box-common a, .list-group-item, #navbar-container, .feed-menu, .checks-message, .section-anuncio-public, .dashboard-contrato,
  #lbl-topics, #dropdown-user, .section-anuncio-public .dropdown, app-controle-feed, app-imagem-user, app-model-novo-anuncio, .componente-anunciante, .publicado-em, .nav-link, .planos-de-beneficios, .card {
    background-color: $light-dark !important;
    border-color: $light-dark !important;
  }

  #lbl-topics {
    margin: 0 !important;
  }

  #content-modal2, .name-input-container text-area, .modal-information h1 {
    background-color: white !important;
    color: black !important;
  }

  app-model-novo-anuncio {
    background: none !important;
  }

  p, h1, h2, h3, h4, h5, span, small {
    background-color: inherit !important;
  }

  .card-menor-sinc {
    background-color: white !important;
  }

  #input-pesquisar, .input-carteira-item, .form-control, .create-new-publi, ::placeholder {
    background-color: white !important;
    border-color: $dark !important;
    color: black !important;
  }

  .container-categoria .titulo-introducao,
  .titulo-step {
    color: white !important;
  }


}
