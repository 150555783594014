@import url('https://fonts.googleapis.com/css2?family=Gowun+Batang&family=IBM+Plex+Sans:wght@500&family=Mukta:wght@400;500&family=Noto+Sans&family=Poppins:wght@300;400;500&family=Raleway:wght@400;500;600;700;800&family=Roboto:wght@300;400;500&display=swap');


*{
  /* AJUSTES DO SCROLL */

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cdcdcd;
    border-radius: 20px;
  }
}


/*.container-with-menu {
  margin-top: 10vh;
  width: 80vw;
  margin-left: 19vw;
}*/
:root {
  --dark-gray: #484F48;


  /* Font-Size */
  --small-fs: 12px;
  --categoriew-fs: 10px;
  --items-fs: 14px;

  --poppinslight: 'Poppins', sans-serif;
  --notosans: 'Noto Sans', sans-serif;
  --mukta:  'Mukta', sans-serif;
  --imb-plex: 'IBM Plex Sans', sans-serif;
  --raleway: 'Raleway', sans-serif;


  --gowunbatang: 'Gowun Batang', serif;
  --roboto: 'Roboto', sans-serif;
}
/*
@font-face{
  font-family: GowunBatang;
  src: url("./assets/fonts/Gowun_Batang/GowunBatang-Regular.ttf");
}

@font-face {
  font-family: IbmPlex;
  src: url("./assets/fonts/IBM_Plex_Sans/IBMPlexSans-ExtraLight.ttf");
}

@font-face {
  font-family: IbmPlex;
  src: url("./assets/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf");
}

@font-face {
  font-family: IbmPlexMedi;
  src: url("./assets/fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf");
}
@font-face {
  font-family: NotoSans;
  src: url("./assets/fonts/Noto_Sans/NotoSans-Regular.ttf");
}

@font-face {
  font-family: PoppinsRegular;
  src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: MuktaLight;
  src: url("./assets/fonts/Mukta/Mukta-Light.ttf");
}

@font-face {
  font-family: Mukta;
  src: url("./assets/fonts/Mukta/Mukta-Regular.ttf");
}*/

/* ---------------------- Font ------------------------- */
.f1 {
  font-family: 'Mukta', sans-serif;
}

h1 {
  font-family: 'Mukta', sans-serif;
}

h2 {
  font-family: 'Mukta', sans-serif;
}

h3 {
  font-family: 'Mukta', sans-serif;
}

h4 {
  font-family: 'Mukta', sans-serif;
}

h5 {
  font-family: 'Mukta', sans-serif;
}

h6 {
  font-family: 'Mukta', sans-serif;
}

span {
  font-family: 'Mukta', sans-serif;
}

div {
  font-family: 'Mukta', sans-serif;
}

bold {
  font-family: 'Mukta', sans-serif;
}

strong {
  font-family: 'Mukta', sans-serif;
}

.t1 {
  font-size: 8px;
}

.t2 {
  font-size: 10px;
}

.t3 {
  font-size: 11px;
}

.t4 {
  font-size: 12px;
}

.t5 {
  font-size: 13px;
}

.t6 {
  font-size: 15px;
}

.t7 {
  font-size: 17px;
}

.t8 {
  font-size: 18px;
}

.t9 {
  font-size: 19px;
}

.t10 {
  font-size: 22px;
}

.t11 {
  font-size: 24px;
}

.t12 {
  font-size: 26px;
}

.t13 {
  font-size: 30px;
}

.t14 {
  font-size: 35px;
}

.t15 {
  font-size: 40px;
}

.t16 {
  font-size: 45px;
}

.t17 {
  font-size: 50px;
}

.textColor {
  color: gray !important;
}

.dGray {
  color: #202020;
}

.mdGray {
  color: #464646;
}

.lightGray {
  color: #6c6c6c;
}

/* ---------------------- Efeito ------------------------- */
.ef {
  transform: scale(1.0);
  transition-duration: 200ms !important;

}

.ef:hover {
  transform: scale(1.004);
  transition-duration: 400ms !important;

}

.ef2 {
  transform: scale(1.0);
  transition-duration: 200ms !important;

}

.ef2:hover {
  transform: scale(1.05);
  background-color: #719100;
  border-radius: 20px;
  transition-duration: 400ms !important;

}

.ef3 {
  transition-duration: 200ms !important;

}

.ef3:hover {
  cursor: pointer;
  box-shadow: 0 2px 3px #dadada;
  transition-duration: 400ms !important;
}

.ef4 {
  transition-duration: 200ms !important;

}

.ef4:hover {
  cursor: pointer;
  box-shadow: 0 2px 7px #bdbdbd;
  transition-duration: 400ms !important;
}

.ef5 {
  box-shadow: 0;
}

.ef5:hover {
  cursor: pointer;
  box-shadow: 0 3px lightgray;
}

.ef6 {
  opacity: 0;
  transition-duration: 200ms !important;
}

.ef6:hover {
  opacity: 0.85;
  transition-duration: 400ms !important;
}

.linkColor {
  color: #272822;
}

.linkColor:hover {
  color: #9ac400;
}



/* ----------------------ALERTAS------------------------- */
.zIndex999 {
  z-index: 99999;
}


/*alert styling*/
.alert-success {
  border-color: #e6e6e6;
  border-left: 5px solid #00986a;
  background-color: #fff;
  color: #888;
}

.alert-info {
  border-color: #e6e6e6;
  border-left: 5px solid #00b3c8;
  background-color: #fff;
  color: #888;
}

.alert-warning {
  border-color: #e6e6e6;
  border-left: 5px solid #f9af2c;
  background-color: #fff;
  color: #888;
}

.alert-danger {
  border-color: #e6e6e6;
  border-left: 5px solid #c82630;
  background-color: #fff;
  color: #888;
}

.alert {
  border-radius: 6px;
  display: table;
  width: 100%;
  padding-left: 78px;
  position: relative;
  padding-right: 60px;
  border: 1px solid #e6e6e6;
}

.alert .close {
  opacity: 0.6;
  float: none;
  position: absolute;
  right: 18px;
  top: 50%;
  margin-top: -10px;
  font-size: 14px;
  color: lightgrey !important;
}

.alert .icon {
  text-align: center;
  width: 58px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #bdbdbd;
  padding-top: 15px;
  border-radius: 6px 0 0 6px;
}

.alert .icon i {
  font-size: 20px;
  color: #fff;
  left: 21px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
}

.alert .icon img {
  font-size: 20px;
  color: #fff;
  left: 18px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
}

/*============ colors ========*/
.alert.alert-success .icon,
.alert.alert-success .icon:after {
  border-style: none;
  background: #00986a;
}

.alert.alert-info .icon,
.alert.alert-info .icon:after {
  border-style: none;
  background: #00b3c8;
}

.alert.alert-warning .icon,
.alert.alert-warning .icon:after {
  border: none;
  background: #f9af2c;
}

.alert.alert-danger .icon,
.alert.alert-danger .icon:after {
  border-style: none;
  background: #c82630;
}

.link {
  position: relative;
  font-size: 15px;
  border: none;
  outline: 0;
  background-color: transparent;
  color: inherit;
  text-decoration: none;
}

#post-principal {
  background-color: white !important;
}

.card-title {
  font-family: var(--poppinslight);
  font-weight: 400;
  color: #8a8a8a !important;
}


#card-description-closed>p:nth-child(2) {
  margin-bottom: 0 !important;
}

#card-description-closed>h5,
#card-description-closed>ol

  /* #card-description-closed>ul */
{
  display: none !important;
}

#card-description p {
  font-family: var(--poppinslight);
}


/* #card-description-closed>h5,
#card-description-closed>ol
#card-description-closed>ul
  {
  display: block;
}
*/

.card-componente {
  border: solid 1px gainsboro !important;

}

.card-componente:hover {
  border: solid 1px #979797FF !important;
}

//<<<<<<< HEAD
.fazer-proposta {
  font-family: var(--poppinslight);
  transition: all .3s ease-in-out !important;
  color: white;
  min-width: 190px !important;
  background-color: #9ac400;
  border-radius: 30px;

}


.fazer-proposta:hover {
  background-color: rgba(205, 231, 111, 1) !important;
  color: black !important;
}

.small-categorie {
  background-color: #fff0c4 !important;
  color: #5c5c5c;
  border-radius: 45px;
  font-size: 10px;
  font-family: var(--poppinslight);
  padding: 5px 10px;
  transition: all .3s ease-in-out;
}

.linha-descricao {
  margin-right: .8rem;
}

#small-categorie:hover {
  background-color: #ffdc73 !important;
  color: white;

}

#metodo-pagamento {
  background-color: #ecffd7;
  padding: 5px;
  border-radius: 15px;
  transition: all .3s ease-in-out;
}

#metodo-pagamento:hover {
  background-color: #B8D744FF;
  color: white;
}



/* SCROLL TO TOP */
.scrollToTop {
  background-color: #d4e5c1;
  position: fixed;
  border-radius: 50%;
  right: 5vh;
  bottom: 5vh;
  color: #8eaa23;
  font-size: 25px;
  width: 50px;
  height: 50px;
  border: solid 1px #8eaa23;
}

.scrollToTop:hover {
  background-color: #B8D744FF;
}

/* SHOW MORE - DETAILS */
.morecontent span {
  display: none;
}

.morelink {
  display: block;
}


#preco {
  margin: auto;
  height: 20px;
}

#tittle-card {
  align-items: center;
  margin-right: 4px !important;

}

.container {
  margin-left: 114px;
}

@media screen and (min-width:1200px) {

  #detalhes-anuncio {
    display: flex;
    flex-direction: row;
    gap: 10px !important;
  }

  #main-card {
    margin-left: 10rem;

  }

  .card-body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}


@media screen and (min-width:1366px) {
  #main-card {
    margin-left: 6rem;

  }

  .container {
    margin-left: 175px;
  }

}

@media screen and (min-width:1200px) and (max-width:1390px) {
  #search-input {
    margin-left: 50px !important;
    max-width: 520px !important;
  }
}

@media screen and (width < 1300px) {
  .list-subitems-control{
    gap: 0;

  }
}


/* BODY CONFIGURATION */
body {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f2ef !important;

}

/* HEADER */
#item-select-menu {
  padding: 0 !important;
  display: flex;
  min-width: 133px !important;
}

#header-menu {
  width: 100vw;

  nav{
    max-height: 55px;
  }
}

.user-icon {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.item-categorys {
  transition: all .3s ease-in-out;
}

.subinfos-public {
  font-family: var(--poppinslight);
  font-size: 12px;
  font-weight: 300;
}

.public-user-img {
  max-width: 30px;
}

.get-localizacao {
  font-family: var(--poppinslight);
  font-size: 12px;
  font-weight: 300;
  min-width: fit-content;
}

.items-destaque {
  font-size: 12px;
  color: #8eaa23;
}

.metodo-pag {
  font-family: var(--poppinslight);
  font-size: 12px;
  font-weight: 300;
}

@media screen and (width > 1400) {
  #main-container {
    max-width: 1300px;
  }
}

.quantia-moedas-gasto {
  font-family: var(--poppinslight);
  font-size: 12px;
  font-weight: 300;
  color: #202020;
  margin: 0;
}

.image-tippz-icon {
  width: 15px;
  height: 15px;
}

/* DESABILITANDO A   LOGO DO CODEIGNITER */
#toolbarContainer{
  display: none !important;
}

/* MODAIS EM GERAL - CENTRALIZAÇÃO */
.container.alert.alert-info.fade.show.text-start.mt-5.lert-dismissible{
  margin: 0 !important;
}

.centralizar-modal{
  margin: 0 !important;
  display: block;

}


.bg-primary {
  background-color: #9ac400 !important;

}

.bg-tertiary {
  background-color: #ff7f00 !important;

}

.text-bg-primary {
  color: #3d6a32 !important;
  background-color: #d6f0e0 !important;
}

.text-tertiary {
  color: #ff7f00 !important;
}

.badge-success {
  color: #3d6a32 !important;
  padding: 0.3rem;
  padding-right: 0.6rem;
  padding-left: 0.6rem;
  background-color: #d6f0e0 !important;
}

.bg-secondary {
  background-color: #0f0f1e !important;
  border: none !important;

}

.form-control-tertiary:focus {
  border: none;
}

.form-control-tertiary:hover {
  box-shadow: 0 0 9px 0 rgba(255, 127, 0, 0.30) !important;
  -webkit-box-shadow: 0 0 9px 0 rgba(255, 127, 0, 0.30) !important;
  -moz-box-shadow: 0 0 9px 0 rgba(255, 127, 0, 0.30) !important;
}

.form-control-tertiary:focus {
  box-shadow: 0 0 9px 0 rgba(255, 127, 0, 0.86) !important;
  -webkit-box-shadow: 0 0 9px 0 rgba(255, 127, 0, 0.86) !important;
  -moz-box-shadow: 0 0 9px 0 rgba(255, 127, 0, 0.86) !important;
}

.btn-primary-custom {
  background-color: #9ac400;
  border: none !important;
}

.btn-tertiary {
  background-color: #ff7f00 !important;
  border: none !important;
}

.text-primary {
  color: #0f0f1e !important;
}

.text-secondary {
  color: #9ac400 !important;
}

.border-primary {
  border-color: #9ac400 !important;
}

.border-secondary {
  border-color: #0f0f1e !important;
}

.hideLeft {
  position: absolute;
  left: -30px;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 1) 47%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 1) 47%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 47%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
  z-index: 9 !important;
}

.hideRight {
  position: absolute;
  right: -30px;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(270deg, rgba(255, 255, 255, 1) 47%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(270deg, rgba(255, 255, 255, 1) 47%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(270deg, rgba(255, 255, 255, 1) 47%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
  z-index: 9 !important;
}

.inputNone:focus {
  outline: none;
}

.nav-pills>li>a {
  color: gray;
}

.nav-pills>li:hover>a,
.nav-pills>li.active>a {
  color: #9ac400;
}

.nav-pills>.nav-item>.nav-link.active {
  background-color: #9ac400 !important;
}

/* ---------------------- preloader  ------------------------- */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9ac400;
  /* cor do background que vai ocupar o body */
  z-index: 9999999999999999999999999999999;
  /* z-index para jogar para frente e sobrepor tudo */
}

#preloader .inner {
  position: absolute;
  top: 50%;
  /* centralizar a parte interna do preload (onde fica a animação)*/
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ---------------------- switch  ------------------------- */
.inactiveMixin {
  content: "";
  position: absolute;
  display: block;
}

.beforeAnimation {
  -moz-transition: .2s cubic-bezier(.24, 0, .5, 1);
  -o-transition: .2s cubic-bezier(.24, 0, .5, 1);
  -webkit-transition: .2s cubic-bezier(.24, 0, .5, 1);
  transition: .2s cubic-bezier(.24, 0, .5, 1);
}

.afterAnimation {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
  -moz-transition: .35s cubic-bezier(.54, 1.60, .5, 1);
  -o-transition: .35s cubic-bezier(.54, 1.60, .5, 1);
  -webkit-transition: .35s cubic-bezier(.54, 1.60, .5, 1);
  transition: .35s cubic-bezier(.54, 1.60, .5, 1);
}

.toggleWrapper input.mobileToggle {
  opacity: 0;
  position: absolute;
}

.toggleWrapper input.mobileToggle+label {
  position: relative;
  display: inline-block;
  user-select: none;
  -moz-transition: .4s ease;
  -o-transition: .4s ease;
  -webkit-transition: .4s ease;
  transition: .4s ease;
  -webkit-tap-highlight-color: transparent;
  height: 25px;
  width: 45px;
  border: 1px solid #e4e4e4;
  border-radius: 60px;
  cursor: pointer !important;
}

.toggleWrapper input.mobileToggle+label:before {
  content: "";
  position: absolute;
  display: block;
  -moz-transition: .2s cubic-bezier(.24, 0, .5, 1);
  -o-transition: .2s cubic-bezier(.24, 0, .5, 1);
  -webkit-transition: .2s cubic-bezier(.24, 0, .5, 1);
  transition: .2s cubic-bezier(.24, 0, .5, 1);
  height: 25px;
  width: 45px;
  top: 0;
  left: 0;
  border-radius: 30px;
}

.toggleWrapper input.mobileToggle+label:after {
  content: "";
  position: absolute;
  display: block;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
  -moz-transition: .35s cubic-bezier(.54, 1.60, .5, 1);
  -o-transition: .35s cubic-bezier(.54, 1.60, .5, 1);
  -webkit-transition: .35s cubic-bezier(.54, 1.60, .5, 1);
  transition: .35s cubic-bezier(.54, 1.60, .5, 1);
  background: whitesmoke;
  height: 20px;
  width: 20px;
  top: 1px;
  left: 0;
  border-radius: 60px;
}

.toggleWrapper input.mobileToggle:checked+label:before {
  background: #9ac400;
  -moz-transition: width .2s cubic-bezier(0, 0, 0, .1);
  -o-transition: width .2s cubic-bezier(0, 0, 0, .1);
  -webkit-transition: width .2s cubic-bezier(0, 0, 0, .1);
  transition: width .2s cubic-bezier(0, 0, 0, .1);
}

.toggleWrapper input.mobileToggle:checked+label:after {
  left: 24px;
}

footer {
  height: 4vh;
}

footer div,
footer div a {
  font-size: 1em !important;
}

.no-link {
  text-decoration: none;
  color: black;
}

/* BUTTON CLOSE - LOGIN MODAL */
.btn-close{
  width: 32px !important;
  height: 32px !important;
}
.btn-close:hover {
  background-color: transparent;
}

.label-input-login{
  border: solid 1px gainsboro !important;
  border-radius: 25px;

}

.label-input-login input:focus{
  border: solid 1px gainsboro !important;
}


/* CONTROL PUBLIC */
.botao-publicacao {
  background-color: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.botao-publicacao:hover {
  background-color: #f9f7f7 !important;
}






/*#control-public {
  width: 100%;
  height: 57.2px;
  background-color: white;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: .2rem;
  border-radius: 5px;
  border: solid 1px #cbcbcb;
  padding-left: 10px;
  padding-right: 10px;
}

#control-public-more {
  width: 100%;
  height: 57.2px;
  background-color: white;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
  border-radius: 6px;
  border: solid 1px #cbcbcb;

}*/



.form-check-input:checked {
  background-color: #0d6efd !important;
  border-color: #0d6efd;
}

.text-justify {
  text-align: justify;
}

.pointer {
  cursor: pointer;
}

@media screen and (max-width:1400px) {
  .list-subitems-control{
    gap: 0;
  }
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';



// ESTILIZAÇÃO APP-ROOT/APP-HEADER/APP-HOME
app-como-funciona-tippz,
app-root {
  display: flex;
  height: 100vh;
  //width: 100vw;
  width: 100%;
  flex-direction: column;

}

app-header{
  position: fixed;
  z-index: 9;
}


/*
app-carteira,
app-perfil,
app-meus-anuncios,*/

app-proposta{
  min-height: calc(100vh - 49px);
  margin-left: 270px;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  justify-content: center;
  background-color: #f5f5f5;
}


app-checkout,
app-novo-anuncio{
  min-height: calc(100vh - 49px);
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  justify-content: center;
}

app-anuncio{
  min-height: calc(100vh - 49px);
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  justify-content: center;
}

app-novo-anuncio{
  justify-content: center;
  align-items: center;
}

app-controle-feed,
app-imagem-user{
  height: fit-content !important;
  display: block;
  padding: 0;
  background-color: white;
  margin: 0;
  border-radius: 8px;
}

app-imagem-user{
  padding: 0.5rem 0.5rem;
}

app-feed-posts{
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;

}

app-aside-welcome{
  max-width: 280px;
}


.modal-content#content-modal2 {
  max-width: 400px !important;
  height: 570px;
  margin: auto;
  width: 95vw !important;
}

@media screen and (width < 550px){
  .modal-content#content-modal2{
    height: fit-content;
  }
}

.modal-content#modal-body-aceite{
  min-width: 60vw;
  min-height: 70vh;
}

.modal-body-app{
  max-width: 280px;
  margin: 0 auto;
  margin-bottom: 3rem;
}

#exampleModalLabel,
#modalDownloadAppLabel{
  color: black;
  font-size: 20px !important;
  font-family: var(--roboto);
  font-weight: 500;
  margin: 0;
}

.modal-description span,
.modal-description{
  font-family: var(--mukta);
  font-weight: 300;
  font-size: 14px;
  line-height: 1.3;
  margin: 0 !important;
}

modal-container.modal.fade.show{
  display: flex !important;
  align-items: center;
  justify-content: center;
}

#content-modal,
#content-modal2,
#content-modal3{
  background-image: url("/assets/_img/bg-app.png");
}

/* AJUSTES DO SCROLL */

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: white;
}

body::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  border-radius: 20px;
}

/* MENU-DROPDOWN */
#menu-dropdown::-webkit-scrollbar {
  width: 5px;
}

#menu-dropdown::-webkit-scrollbar-track {
  background: white;
}

#menu-dropdown::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  border-radius: 20px;
}


// LAPTOP - MEDIA QUERIES
@media screen and (width >= 1024px) and (width <= 1280px) {
  app-aside-welcome{
    max-width: 220px;
  }

  carousel.carousel-leiloes{
    max-width: 750px !important;
  }
}

@media screen and (width > 900px) and (width < 1024px) {
  //app-anuncio,
  app-home{
    margin: 49px 0 0 205px !important;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .aside-welcome{
    display: none;
  }
}

// TABLET - MEDIA QUERIES
@media screen and (width <= 900px) {
  app-anuncio,
  app-home{
    margin: 49px 0 0 0 !important;
  }

  /* Menu - Controller - User */

}


.ck.ck-editor__editable_inline {
  min-height: 300px;
}


.online-icon,.offline-icon{
  position: absolute;
  width: 10px;
  height: 10px;
  bottom: 0px;
  left: 19px;
  border-radius: 14px;
  border: solid 2px white;
}

.online-icon {
  background-color: rgb(20, 229, 20);
}

.offline-icon{
  background-color: rgb(163, 163, 163);
}

lib-ng-toast{
  .toast-right-top.info{
    height: fit-content;
  }
}

.btn-primary-custom {
  font-family: var(--poppinslight);
  transition: var(--df-transition) !important;
  color: white;
  background-color: #9ac400 !important;
}

.modal.show .modal-dialog{
  /*background-color: transparent !important;
  height: 70vh !important;
  width: 55vw !important;*/
  //max-width: 55vw !important;

}
.modal-content-anuncio .modal-content {
  background-color: transparent;
  border-style: none;
  width: 55vw !important;
}

/* ADS - PREMIUM */
.premium-ads {
  height: fit-content;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  border: solid 1px gainsboro;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: .3rem;


}

.premium-ads img {
  width: 24px;
  height: 24px;
}



.premium-ads h3 {
  font-family: var(--roboto) !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  margin: 0;
  color: #484F48;
  margin-bottom: 0.3rem;
}

.premium-ads p {
  font-family: var(--roboto) !important;
  font-weight: 400;
  font-size: 13px !important;
  margin: 0;
  color: #9FAB9A;
}

.botao-premium {
  font-family: var(--roboto);
  font-size: 14px;
  height: 35px !important;
  padding: 0 !important;
  width: 100%;
  border-radius: 7px;
  color: white;
  background-color: #9ac400;
  text-align: center;
  line-height: 35px;
  text-decoration: none;
  text-transform: none;
  font-weight: 700;
}


.botao-premium:hover {
  background-color: #b8d744;
  color: white;
}

.description-ads {
  display: flex;
  align-items: center;
  gap: 0.5rem;

}

#container-master > *:not(app-como-funciona-tippz){
  margin-top: 45px;
}

.modal-open{
  overflow-y: auto !important;
}

@media screen and (width <= 768px){
  #container-master > *:not(app-como-funciona-tippz){
    margin-top: 1rem !important;
  }

  app-perfil,
  app-nova-proposta{
    width: 100% !important;
  }

  app-feed{
      max-width: 100%;
  }
}
.toast-right-top {
  height: fit-content !important;
}
